/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');


// for datepicker
//import $ from 'jquery';
//window.$ = window.jQuery = $;
//import 'jquery-ui/ui/widgets/datepicker.js';
//for datepicker end
window.Vue = require('vue');

import Vue from 'vue'
//import vuetify from './plugins/vuetify' // path to vuetify export

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('table-component', require('./components/TableComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


//-------------------------------------------------------------------------------

const app = new Vue({
   //el: '#app', //wegen  autofocus auskommentiert
});

//new Vue({
//    vuetify
//}).$mount('#app')
//-------------------------------------------------------------------------------

$(document).ready(function()
{
//window.addEventListener('load', function(){
    //$("[autofocus]").first().focus();
    
//    $('.datepicker').datepicker({
//        autoclose: true,
//        format: 'yyyy-mmm-dd'
//
//    });

    // ------------------------------------------------------- //
    // Multi Level dropdowns
    // ------------------------------------------------------ //
    $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function(event)
    {
        event.preventDefault();
        event.stopPropagation();

        $(this).siblings().toggleClass("show");


        if (!$(this).next().hasClass('show'))
        {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e)
        {
            $('.dropdown-submenu .show').removeClass("show");
        });
    });


    $('[data-toggle="tooltip"]').tooltip({html: true});


    //$('.select-all').on('click', function(e)
    $(document).delegate('.select-all', 'click', function()
    {
        var $this = this;
        $.each($('.select-item'), function(i, item)
        {
            $(item).prop('checked', $this.checked);
        });
    });

    autoheight();
    tabheadFixed();

    $('[data-toggle=confirmation]').confirmation(
    {
        rootSelector: '[data-toggle=confirmation]',
        container: 'body'
    });
    $('[data-toggle=confirmation-singleton]').confirmation(
    {
        rootSelector: '[data-toggle=confirmation-singleton]',
        container: 'body'
    });
    $('[data-toggle=confirmation-popout]').confirmation(
    {
        rootSelector: '[data-toggle=confirmation-popout]',
        container: 'body'
    });

    $('#confirmation-delegate').confirmation(
    {
        selector: 'button'
    });

    $('[data-toggle=custom-confirmation-events]').confirmation(
    {
        rootSelector: '[data-toggle=custom-confirmation-events]',
        container: 'body'
    })
    .on('mouseenter', function()
    {
        $(this).confirmation('show');
    })
    .on('myevent', function()
    {
        alert('"myevent" triggered');
    });

    autoheight();
    //tabheadFixed();

    // Javascript to enable link to tab
    var hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
    if (hash)
    {
        $('.nav-tabs a[href="#' + hash + '"]').tab('show');
        $('.open_tab').val('#' + hash);
        if (typeof open_tab != "undefined")
        {
            $('#open_tab').val('#' + hash);
        }
    }
    $('.nav-tabs a.is-invalid:first').tab('show');

});


function tabheadFixed()
{
    // Tabellenkopf fixieren
    if ($(".content table.tabheadfixed").length)
    {

        $(".content").scroll(function()
        {

            copyTabHead();

            var offset = $(this).scrollTop();
            if (offset >= $('.content table.tabheadfixed th').height())
            {
                $("#tab_copy").removeClass("d-none");
            }
            else if (offset < $('.content table.tabheadfixed th').height())
            {
                $("#tab_copy").addClass("d-none");
            }
        });
    }
}

function copyTabHead()
{
    if ($(".content table.tabheadfixed").length)
    {
        $('#tab_copy').remove();
        $('.content').css("position", "relative");
        var position = $(".content").offset();
        var position_top = position.top;
        var position_table = $(".content table.tabheadfixed").offset();
        var position_table_left = position_table.left + 1;
        var position_table_width = $('.content table.tabheadfixed').width();

        $('.content').append('<table class="table table-sm d-none" id="tab_copy" style="position:fixed;width:100%;max-width:' + position_table_width + 'px; top:' + position_top + 'px;left:' + position_table_left + 'px;"></table>');
        $('#tab_copy').append( '<tr>' );
        $.each($('.content table.tabheadfixed th'), function(i, th_item)
        {
            $('#tab_copy tr').append( '<td style="width: ' + $(th_item).width() + 'px;height: 0px!important;"></td> ');
        });
        $('#tab_copy').append( '</tr>' );
        $('.content table.tabheadfixed thead').clone().appendTo('.content #tab_copy');
    }
}

function autoheight()
{
    // check empty divs
    if ($('.leftbar').children().length)
    {
        $('.leftbar').addClass("d-block");
    }
    if ($('.toolbarwrapper').children().length)
    {
        $(".toolbarwrapper").addClass("d-flex");
    } 
    if ($('.footerbarwrapper').children().length)
    {
        $(".footerbarwrapper").addClass("d-block");
        var height_footer = $('.footer').height() + $('.footerbarwrapper').height() + 35;
    } else
    {
        var height_footer = $('.footer').height();
    }

    // Höhe des Content-Bereiches
    if ($(".content").length)
    {
        var position = $(".content").offset();
        var position_top = position.top;
        var fenster = $(window).height();
        var content_height =  fenster - position_top - height_footer;
        $('.content').css("height",content_height + "px");
    }
}

$(window).resize(function()
{
    autoheight();
    tabheadFixed();
});
